import { Box, HStack, VStack, Stack, Text, Button, ButtonGroup, Avatar, AvatarBadge, AvatarGroup} from '@chakra-ui/react'
import {CountDown} from '@/Components/Helpers'
import { useForm } from '@inertiajs/react';

const CreatorItem = ({creator, height, column, auth, from}) => {

    const {get} = useForm({})
    
    const CreatorDetail = (id) => {
        return get(route('creator', {id}))
    }

    return  (

        <div className={column}> 
        
            <Box className='creator-card' height={180} backgroundColor={'#5757644a'} backdropFilter={'blur(10px)'} borderRadius={30} padding={'10px 13px'}>

                <Box className="row" height={'100%'}>
                    <Box className="col-lg-4">
                        <VStack className='creator-img' height={'100%'} backgroundSize={'cover'} backgroundPosition={'center'} backgroundImage={`url(${creator.image ? window.location.origin+'/'+creator.image : window.location.origin+'/storage/uploads/collections/gray.png'})`} justifyContent={'flex-end'} borderRadius={20} margin={'auto 0'}>

                        </VStack>
                    </Box>

                    <Box className="col-lg-4 mb-3">
                        <VStack spacing={1} height={'100%'} alignItems={'flex-start'} paddingTop={5}>

                            <Text mb={3} className='text-white' fontFamily={'Cosmata Bold'} fontSize={22}>{creator.username}</Text>

                            <HStack spacing={4}>
                                <Box backgroundColor={'#637eeb'} borderRadius={100} padding={1}>
                                    <img width={24} style={{filter: 'brightness(4)'}} src={`${window.location.origin}/images/icons/eth-logo.png`} alt="" />
                                </Box>
                                <Box>
                                    <Text marginBottom={-1} className='text-white' fontFamily={'Boucle2'} fontSize={15}>{parseFloat(creator.income).toFixed(4)} ETH</Text>
                                </Box>
                            </HStack>

                        </VStack>
                    </Box>

                    <Box className="col-lg-4" >
                        <VStack paddingBottom={0.5} height={'100%'} justifyContent={'flex-end'}>
                            <Button onClick={() => {
                                if(from){
                                    if(auth?.user){
                                        CreatorDetail(creator.username)
                                    }else{
                                        return window.location.href = '/login'
                                    }
                                }else{
                                    CreatorDetail(creator.username)
                                }
                            }} _hover={{background: 'none'}} _focus={{outline: 0}} width={'100%'} fontSize={20} borderRadius={8} fontFamily={'Boucle2'} className="brand-bg text-dark">
                                View
                            </Button>
                        </VStack>
                    </Box>

                </Box>

            </Box>  

        </div>

    )
    
}

export default CreatorItem